import { useContext, useEffect, useRef, useState } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { callApi, CDN_URL, formatDate, formatDateForInput, formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { getInlineLoader } from "../Helpers";
import TabbedView from "../components/TabbedView";
import { STR_ACCOUNTS_RECEIVABLE, STR_AMOUNT_PAID, STR_AMOUNT_PAYABLE, STR_ASK_CONTINUE, STR_BALANCE, STR_CALL, STR_CUSTOMER_DETAILS, STR_CUSTOMER_WILL_BE_CLIENT, STR_DASHBOARD, STR_DATE, STR_DELETE_SALE, STR_DISCOUNT, STR_EDIT, STR_GRAND_TOTAL, STR_INITIAL_AMOUNT, STR_INVALID_AMOUNT, STR_INVALID_DATE, STR_INVOICE, STR_MENU_ARTICLES, STR_MENU_STORIES, STR_MENU_UTILITIES, STR_NAME, STR_PARTICULARS, STR_PAYABLE, STR_PAYMENTS, STR_PROCESS_ORDER, STR_PROCESSED, STR_PRODUCT_CODE, STR_PROMISE, STR_PROMISE_DUE_DATE, STR_PURCHASES, STR_QUANTITY, STR_RECEIVABLE, STR_RECEIVE_PAYMENT, STR_RECORD_PAYMENTS, STR_REPORT_FOLLOWUP, STR_REPORT_SALES, STR_REPORT_VISIT, STR_SALES, STR_SEND_EMAIL, STR_SEND_SMS, STR_SUB_TOTAL, STR_SUBMIT, STR_SUCCESS, STR_TODOS, STR_TOTAL_AMOUNT, STR_TURN_TO_CLIENT, STR_UNIT, STR_UNIT_PRICE, STR_UNPROCESSED, STR_VIEW_MORE_INFO, STR_VISITS, STR_WATEJA } from "../Strings";
import CustomersHome from '../views/CustomersHome';
import { MdCall, MdCheckCircle, MdEdit, MdEmail, MdFacebook, MdMessage, MdOutlineFacebook, MdOutlineWhatsapp, MdPrint, MdWhatsapp } from "react-icons/md";
import { BiInfoCircle, BiLogoLinkedin, BiLogoWhatsapp, BiPurchaseTag } from "react-icons/bi";
import { SlUserFollowing } from "react-icons/sl";
import { BsInstagram, BsTelegram } from "react-icons/bs";
import CustomerWidget from '../ones/CustomerWidget';
import html2canvas from "html2canvas";
import MobileInterface from "../MobileInterface";

export default function View({ id }) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [amountPaid, setAmountPaid] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [datePaid, setDatePaid] = useState("");

  const receiptRef = useRef(null);

  async function init() {
    //run initializations here
    console.log(appContext.currentUserRole);

    setReady(false);
    await getData()
    setReady(true);
  }

  const base64ToFile = (base64String, filename) => {
    const arr = base64String.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const printReceipt = async () => {
    setLoading(true);
    if (receiptRef.current) {
      try {
        // Convert the receiptRef to an image using html2canvas
        const canvas = await html2canvas(receiptRef.current);
        const image = canvas.toDataURL("image/png");

        // Convert the base64 image to a File object
        const file = base64ToFile(image, 'receipt.png');


        await callApi("upload-receipt", { receipt: file }).then((response) => {
          if (response.status === 1) {
            MobileInterface.openLink(CDN_URL + response.data);

          } else {
            appContext.tellError("Failed to print receipt");
          }
        })
      } catch (error) {
        console.error("Error generating or uploading receipt image:", error);
      }
    } else {
      //console.error("Receipt reference is not set.");
    }
    setLoading(false);
  };

  async function getData() {
    await callApi("get-sale-data", { id }).then(response => {
      if (response.status === 1) {
        setData(response.data);
      }
    })
  }

  async function deleteSale() {
    setLoading(true);
    await callApi("delete-sale", { id }).then(response => {
      if (response.status === 1) {
        appContext.tellMessage(STR_SUCCESS[appContext.language]);
        appContext.navBack();

        //refresh data
        appContext.getSalesRecords();
        appContext.getTotalSales();
        appContext.getTotalTodaySales();
      } else {
        appContext.tellError(response.msg);
      }
    })
    setLoading(false);
  }

  async function processSale() {
    if (datePaid.trim().length > 0) {
      if (!isNaN(amountPaid) && Number(amountPaid) >= 0 && (Number(amountPaid) + Number(discount)) <= Number(data.totalAmount)) {
        setLoading(true);
        await callApi("process-sale", { id, amountPaid, datePaid, discount }).then(response => {
          if (response.status === 1) {
            appContext.tellMessage(STR_SUCCESS[appContext.language]);
            setAmountPaid(0);
            setDatePaid("");
            getData()
          } else {
            appContext.tellError(response.msg);
          }
        })
        setLoading(false);
      } else {
        appContext.tellError(STR_INVALID_AMOUNT[appContext.language]);
      }
    } else {
      appContext.tellError(STR_INVALID_DATE[appContext.language]);
    }

  }

  async function recordPayment() {
    if (datePaid.trim().length > 0) {
      if (!isNaN(amountPaid) && Number(amountPaid) > 0 && Number(amountPaid) <= Number(data.balance)) {
        setLoading(true);
        await callApi("record-sale-payment", { id, amountPaid, datePaid }).then(response => {
          if (response.status === 1) {
            appContext.tellMessage(STR_SUCCESS[appContext.language]);
            setAmountPaid(0);
            setDatePaid("");
            setDiscount(0)
            getData()
          } else {
            appContext.tellError(response.msg);
          }
        })
        setLoading(false);
      } else {
        appContext.tellError(STR_INVALID_AMOUNT[appContext.language]);
      }
    } else {
      appContext.tellError(STR_INVALID_DATE[appContext.language]);
    }

  }


  useEffect(() => {
    init();
  }, [id])

  useEffect(() => {
    if (data) {
      setDiscount(Number(data.discount));
      setDatePaid(formatDateForInput(data.dateCreated))
    }
  }, [data])

  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [loading])

  if (ready && data) {
    return (
      <div
        className="container"
      >
        <h6
          className="mSectionTitle"
        >
          {data.title}
          <span className="font-semi-bold text-muted" style={{ display: "block", fontSize: "14px" }}>{STR_SALES[appContext.language]}</span>
        </h6>

        {
          (data.customerData) ?
            <CustomerWidget data={data.customerData} />
            : ""
        }
        <hr />


        <div className="row">
          <div className="col-12 mb-2 mt-2 text-end">
            <span
              className={data.status === 'processed' ? "badge bg-success" : "badge bg-danger"}
              style={{
                fontSize: "14px",
                textTransform: "uppercase"
              }}
            >
              {data.status === 'processed' ? STR_PROCESSED[appContext.language] : STR_UNPROCESSED[appContext.language]}
            </span>
          </div>

          <div className="col-6 mb-2 mt-2">
            <label style={{ fontSize: "12px" }} className="form-label text-secondary">{STR_TOTAL_AMOUNT[appContext.language]}</label>
            <h6
              className="font-regular"
              style={{
                fontSize: "24px",
              }}
            >
              {formatMoney(data.totalAmount)}
              <span className="font-semi-bold text-muted" style={{ display: "block", fontSize: "14px" }}>TZS</span>
            </h6>
          </div>

          <div className="col-6 mb-2 mt-2">
            <label style={{ fontSize: "12px" }} className="form-label text-secondary">{STR_DISCOUNT[appContext.language]}</label>
            <h6
              className="font-regular"
              style={{
                fontSize: "24px",
              }}
            >
              {formatMoney(data.discount)}
              <span className="font-semi-bold text-muted" style={{ display: "block", fontSize: "14px" }}>TZS</span>
            </h6>
          </div>

          <div className="col-6 mb-2 mt-2">
            <label style={{ fontSize: "12px" }} className="form-label text-secondary">{STR_AMOUNT_PAID[appContext.language]}</label>
            <h6
              className="font-regular"
              style={{
                fontSize: "24px",
              }}
            >
              {formatMoney(data.amountPaid)}
              <span className="font-semi-bold text-muted" style={{ display: "block", fontSize: "14px" }}>TZS</span>
            </h6>
          </div>

          <div className="col-6 mb-2 mt-2">
            <label style={{ fontSize: "12px" }} className="form-label text-secondary">{STR_BALANCE[appContext.language]}</label>
            <h6
              className="font-regular"
              style={{
                fontSize: "24px",
              }}
            >
              {formatMoney(data.balance)}
              <span className="font-semi-bold text-muted" style={{ display: "block", fontSize: "14px" }}>TZS</span>
            </h6>
          </div>

          <div className="col-12 mb-2 mt-2">
            <label style={{ fontSize: "12px" }} className="form-label text-secondary">{STR_PARTICULARS[appContext.language]}</label>

            <div style={{ overflowX: 'auto' }}> {/* Wrapper for horizontal scrolling */}
              <table className="table table-striped" style={{ minWidth: '500px' }}> {/* Set minWidth */}
                <thead>
                  <tr>
                    <th style={{ fontSize: "12px" }} className="font-bold">
                      {STR_PRODUCT_CODE[appContext.language]}
                    </th>
                    <th style={{ fontSize: "12px" }} className="font-bold">
                      {STR_NAME[appContext.language]}
                    </th>
                    <th style={{ fontSize: "12px" }} className="font-bold">
                      {STR_UNIT[appContext.language]}
                    </th>
                    <th style={{ fontSize: "12px" }} className="font-bold">
                      {STR_QUANTITY[appContext.language]}
                    </th>
                    <th style={{ fontSize: "12px" }} className="font-bold">
                      {STR_UNIT_PRICE[appContext.language]}
                    </th>
                    <th style={{ fontSize: "12px" }} className="font-bold">
                      {STR_SUB_TOTAL[appContext.language]}
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {
                    JSON.parse(data.items).map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>{item.code || 'N/A'}</td>
                          <td>{item.name}</td>
                          <td>{item.unit}</td>
                          <td>{item.quantity}</td>
                          <td>{formatMoney(item.unitPrice)}</td>
                          <td>{formatMoney(item.subTotal)}</td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>

          </div>

          {
            (
              data.status === 'unprocessed' &&
              (
                appContext.currentUserRole === 'manager' ||
                appContext.currentUserRole === 'accountant' ||
                appContext.currentUserRole === 'cashier'
              )
            ) ?
              <div className="col-12 mb-2 mt-2">
                <div className="card">
                  <div className="card-body">
                    <h6 className="card-title">{STR_PROCESS_ORDER[appContext.language]}</h6>

                    <div className="mb-2 mt-4">
                      <label>{STR_AMOUNT_PAID[appContext.language]}</label>
                      <input
                        className="form-control"
                        value={amountPaid}
                        onChange={(e) => setAmountPaid(e.target.value)}
                      />
                    </div>

                    <div className="mb-2 mt-4">
                      <label>{STR_DISCOUNT[appContext.language]}</label>
                      <input
                        className="form-control"
                        value={discount}
                        onChange={(e) => setDiscount(e.target.value)}
                      />
                    </div>

                    <div className="mb-2 mt-2">
                      <label>{STR_DATE[appContext.language]}</label>
                      <input
                        className="form-control"
                        value={datePaid}
                        onChange={(e) => setDatePaid(e.target.value)}
                        type="date"
                      />
                    </div>

                    <div className="mb-2 mt-2 text-end">
                      <button
                        className="btn btn-secondary btn-rounded mt-4"
                        onClick={() => {
                          appContext.activateDialog({
                            message: STR_ASK_CONTINUE[appContext.language],
                            onConfirm: processSale,
                          })
                        }}
                      >
                        {STR_SUBMIT[appContext.language]}
                      </button>
                    </div>

                  </div>
                </div>
              </div>
              : ""
          }

          {
            (
              data.status === 'processed' &&
              (
                appContext.currentUserRole === 'manager' ||
                appContext.currentUserRole === 'accountant' ||
                appContext.currentUserRole === 'cashier'
              )
            ) ?
              <div className="col-12 mb-2 mt-2">
                <div className="card">
                  <div className="card-body">
                    <h6 className="card-title">{STR_RECORD_PAYMENTS[appContext.language]}</h6>

                    <div className="mb-2 mt-4">
                      <label>{STR_AMOUNT_PAID[appContext.language]}</label>
                      <input
                        className="form-control"
                        value={amountPaid}
                        onChange={(e) => setAmountPaid(e.target.value)}
                        type="number"
                      />
                    </div>

                    <div className="mb-2 mt-2">
                      <label>{STR_DATE[appContext.language]}</label>
                      <input
                        className="form-control"
                        value={datePaid}
                        onChange={(e) => setDatePaid(e.target.value)}
                        type="date"
                      />
                    </div>

                    <div className="mb-2 mt-2 text-end">
                      <button
                        className="btn btn-secondary btn-rounded mt-4"
                        onClick={() => {
                          appContext.activateDialog({
                            message: STR_ASK_CONTINUE[appContext.language],
                            onConfirm: recordPayment,
                          })
                        }}
                      >
                        {STR_SUBMIT[appContext.language]}
                      </button>
                    </div>

                  </div>
                </div>
              </div>
              : ""
          }

          

          {
            (JSON.parse(data.transactionsHistory).length > 0) ?
            <div className="col-12 mb-2 mt-2">
              <label style={{ fontSize: "12px" }} className="form-label text-secondary">{STR_PAYMENTS[appContext.language]}</label>

              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>{STR_AMOUNT_PAID[appContext.language]}</th>
                    <th>{STR_DATE[appContext.language]}</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    JSON.parse(data.transactionsHistory).map((item, i) => {
                      return (
                        <tr
                          key={i}
                        >
                          <td>{formatMoney(item.amount)}</td>
                          <td>{formatDate(item.date).substring(0, 10)}</td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
            : ""
          }
            

        </div>

        <div 
          className="mb-2 mt-2"
          style={{
            width: "100%",
            overflow: "auto",
          }}
        >
          <label style={{ fontSize: "12px" }} className="form-label text-secondary">{STR_INVOICE[appContext.language]}</label>
          <br/>
          <button
            className="btn btn-rounded"
            onClick={printReceipt}
          >
            <MdPrint size={32}/>
          </button>

          <div 
            className="bg-surface"
            style={{
              minWidth: "600px",
              padding: "20px"
            }}
            ref={receiptRef}
          >
            <div className="row">

              <div className="col-6">
                <h6
                  style={{
                    fontSize: "24px",
                  }}
                >
                  {appContext.currentBusinessData.name}
                </h6>
              </div>

              <div className="col-6">
                <h6
                  style={{
                    fontSize: "14px",
                  }}
                  className="font-bold"
                >
                  {STR_CUSTOMER_DETAILS[appContext.language]}
                </h6>
                <h6
                  className=""
                  style={{
                    fontSize: "20px",
                    textTransform: "capitalize",
                  }}
                >
                  {data.customerData.name}
                </h6>
                <h6
                  className=""
                  style={{
                    fontSize: "16px"
                  }}
                >
                  {data.customerData.phone}
                </h6>
              </div>

              <div className="col-12">
                <h6
                  className="font-bold"
                  style={{
                    fontSize: "14px"
                  }}
                >
                  INVOICE: #{data.id}
                  <span
                    className="d-block text-muted"
                    style={{
                      fontSize: "12px"
                    }}
                  >
                    {data.title}
                  </span>
                  <span
                    className="d-block font-regular"
                    style={{
                      fontSize: "14px"
                    }}
                  >
                    {formatDate(data.dateCreated).substring(0,10)}
                  </span>
                </h6>
              </div>

              
              <div className="col-12 mb-2 mt-2">

                <div style={{ overflowX: 'auto' }}> {/* Wrapper for horizontal scrolling */}
                  <table className="table table-striped" style={{ minWidth: '500px' }}> {/* Set minWidth */}
                    <thead>
                      <tr>
                        <th style={{ fontSize: "12px" }} className="font-bold">
                          {STR_PRODUCT_CODE[appContext.language]}
                        </th>
                        <th style={{ fontSize: "12px" }} className="font-bold">
                          {STR_NAME[appContext.language]}
                        </th>
                        <th style={{ fontSize: "12px" }} className="font-bold">
                          {STR_UNIT[appContext.language]}
                        </th>
                        <th style={{ fontSize: "12px" }} className="font-bold">
                          {STR_QUANTITY[appContext.language]}
                        </th>
                        <th style={{ fontSize: "12px" }} className="font-bold">
                          {STR_UNIT_PRICE[appContext.language]}(TZS)
                        </th>
                        <th style={{ fontSize: "12px" }} className="font-bold">
                          {STR_SUB_TOTAL[appContext.language]} (TZS)
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {
                        JSON.parse(data.items).map((item, i) => {
                          return (
                            <tr key={i}>
                              <td>{item.code || 'N/A'}</td>
                              <td>{item.name}</td>
                              <td>{item.unit}</td>
                              <td>{item.quantity}</td>
                              <td>{formatMoney(item.unitPrice)}</td>
                              <td>{formatMoney(item.subTotal)}</td>
                            </tr>
                          )
                        })
                      }
                      <tr>
                        <td className="font-bold">{STR_GRAND_TOTAL[appContext.language]}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="font-bold">{formatMoney(data.totalAmount)}</td>
                      </tr>

                      {
                        (Number(data.discount) > 0) ?
                        <tr>
                          <td className="font-bold">{STR_DISCOUNT[appContext.language]}</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td className="font-bold">{formatMoney(data.discount)}</td>
                        </tr>
                        :""
                      }

                      <tr>
                        <td className="font-bold">{STR_AMOUNT_PAYABLE[appContext.language]}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="font-bold">{formatMoney(data.totalAmount - data.discount)}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

              </div>
              
            </div>
          </div>
        </div>

        {
          (
            Number(appContext.currentBusinessData.creatorId) === Number(appContext.currentUserData.id)
          ) ?
          <div 
            className="mb-2 mt-4"
            onClick={() => {
              appContext.activateDialog({
                msg: STR_DELETE_SALE[appContext.language]+"?",
                onConfirm: deleteSale,
              })
            }}
          >
            <button
              className="btn btn-padded btn-block btn-danger"
            >
              {STR_DELETE_SALE[appContext.language]}
            </button>
          </div>
          :""
        }

      </div>
    )
  } else {
    return (
      <div
        className="container mSupportLoading"
      >
        {getInlineLoader()}
      </div>
    );
  }


}