import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { callApi, formatDate, formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { getInlineLoader } from "../Helpers";
import TabbedView from "../components/TabbedView";
import { STR_ADD_NEW, STR_AVAILABLE_STOCK, STR_CLIENTS, STR_DASHBOARD, STR_DELETE_THIS_ENTRY, STR_FILTER_BY_NAME, STR_FILTER_BY_NAME_OR_PHONE, STR_LEADS, STR_LEDGER, STR_MENU_ARTICLES, STR_MENU_STORIES, STR_MENU_UTILITIES, STR_MY_CUSTOMERS, STR_NO_RECORDS, STR_OPERATION_COSTS_TODAY, STR_SALES_TODAY, STR_STOCKLIST, STR_SUCCESS, STR_TODOS, STR_WATEJA } from "../Strings";
import { MdDelete, MdSearch } from "react-icons/md";
import OneRecord from '../ones/OneRecord';
import BusinessNameStrip from "../ones/BusinessNameStrip";

export default function View(props) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState("");


  async function init() {
    //run initializations here
    setReady(false);
    await appContext.getLedger();
    setReady(true);
  }

  async function deleteLedgerEntry(entryId) {
    setLoading(true);
    await callApi('delete-ledger-entry', { entryId, businessId: appContext.currentBusinessId }).then(response => {
      if(response.status === 1) {
        appContext.getLedger();
        appContext.tellMessage(STR_SUCCESS[appContext.language]);
      } else {
        appContext.tellError(response.msg);
      }
    })
    setLoading(false);
  }

  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [ loading ])


  if (ready) {
    return (
      <div
        className="container"

      >
        <BusinessNameStrip/>
        <h1 className="mSectionTitle mt-4">{STR_LEDGER[appContext.language]}</h1>

        <div
          style={{ overflowX: 'auto' }}
          onTouchStart={(e) => e.stopPropagation()}
          onTouchEnd={(e) => e.stopPropagation()}
        > {/* Wrapper for horizontal scrolling */}
          <table className="table table-striped" style={{ minWidth: '600px' }}> {/* Set minWidth */}
            <thead>
              <tr>
                <th style={{ fontSize: "12px" }} className="font-bold">
                  Title
                </th>
                <th style={{ fontSize: "12px" }} className="font-bold">
                  Account name
                </th>

                <th style={{ fontSize: "12px" }} className="font-bold">
                  Amount in TZS
                </th>
                <th style={{ fontSize: "12px" }} className="font-bold">
                  Debit/Credit
                </th>
                <th style={{ fontSize: "12px" }} className="font-bold">
                  Date
                </th>
                <th style={{ fontSize: "12px" }} className="font-bold">
                  Bill
                </th>
                <th style={{ fontSize: "12px" }} className="font-bold">
                  Sale
                </th>
                <th style={{ fontSize: "12px" }} className="font-bold">
                  Vendor
                </th>
                {
                  (
                    Number(appContext.currentBusinessData.creatorId) === Number(appContext.currentUserData.id)
                  ) ?
                  <th style={{ fontSize: "12px" }} className="font-bold">
                    Delete
                  </th>
                  :""
                }
              </tr>
            </thead>

            <tbody>
              {
                (appContext.ledger) ?
                  appContext.ledger.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>{item.title}</td>
                        <td>{item.accountTitle}</td>
                        <td>{formatMoney(item.amount)}</td>
                        <td>
                          {(item.debit === 'yes') ? 'Debit' : ''}
                          {(item.credit === 'yes') ? 'Credit' : ''}
                        </td>
                        <td>{formatDate(item.dateCreated).substring(0, 10)}</td>
                        <td>
                          {
                            (item.billId) ?
                              <button
                                className="btn btn-sm btn-dark"
                                onClick={() => {
                                  appContext.navTo({
                                    item: 'view',
                                    subItem: 'bill',
                                    extraItem: item.billId,
                                  })
                                }}
                              >
                                Bill
                              </button>
                              : ""
                          }
                        </td>
                        <td>
                          {
                            (item.invoiceId) ?
                              <button
                                className="btn btn-sm btn-dark"
                                onClick={() => {
                                  appContext.navTo({
                                    item: 'view',
                                    subItem: 'sale',
                                    extraItem: item.invoiceId,
                                  })
                                }}
                              >
                                Sale
                              </button>
                              : ""
                          }
                        </td>
                        <td>
                          {
                            (item.vendorId) ?
                              <button
                                className="btn btn-sm btn-dark"
                                onClick={() => {
                                  appContext.navTo({
                                    item: 'view',
                                    subItem: 'vendor',
                                    extraItem: item.vendorId,
                                  })
                                }}
                              >
                                Vendor
                              </button>
                              : ""
                          }
                        </td>
                        {
                          (
                            Number(appContext.currentBusinessData.creatorId) === Number(appContext.currentUserData.id)
                          ) ?
                          <td>
                            <MdDelete
                              size={24}
                              className="text-danger"
                              style={{
                                cursor: "pointer"
                              }}
                              onClick={() => {
                                appContext.activateDialog({
                                  message: STR_DELETE_THIS_ENTRY[appContext.language]+"?",
                                  onConfirm: () => deleteLedgerEntry(item.id),
                                })
                              }}
                            />
                          </td>
                          :""
                        }
                      </tr>
                    )
                  })
                  : ""
              }
            </tbody>
          </table>
        </div>
      </div>
    )
  } else {
    return (
      <div
        className="container mSupportLoading"
      >
        {getInlineLoader()}
      </div>
    );
  }


}