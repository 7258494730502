import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { getInlineLoader } from "../Helpers";
import TabbedView from "../components/TabbedView";
import { STR_ACCOUNTS_PAYABLE, STR_ACCOUNTS_RECEIVABLE, STR_ADD_NEW, STR_AVAILABLE_STOCK, STR_BUSINESS, STR_CLIENTS, STR_CREATE_GOAL, STR_DASHBOARD, STR_EDIT, STR_FILTER_BY_NAME, STR_FILTER_BY_NAME_OR_PHONE, STR_FIXED_ASSETS, STR_INVESTMENT, STR_LEADS, STR_MENU_ARTICLES, STR_MENU_STORIES, STR_MENU_UTILITIES, STR_MY_CUSTOMERS, STR_NO_RECORDS, STR_OPERATION_COSTS, STR_OPERATION_COSTS_TODAY, STR_PRODUCTION, STR_PURCHASES, STR_SALES, STR_SALES_TODAY, STR_STOCK, STR_STOCKLIST, STR_SUBSCRIPTION_PACKAGE, STR_TODOS, STR_USERS, STR_WATEJA, STR_WORKING_CAPITAL } from "../Strings";
import { MdDataUsage, MdEdit, MdMoney, MdPeople, MdSearch, MdSubscriptions } from "react-icons/md";
import OneRecord from '../ones/OneRecord';
import { TbTarget } from "react-icons/tb";

export default function View(props) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);
  const [filter, setFilter] = useState("");


  async function init() {
    //run initializations here
    setReady(false);
    await appContext.getCurrentBusinessData();
    setReady(true);
  }

  function createGoal() {

  }

  useEffect(() => {
    init();
  }, [])


  if (ready) {
    return (
      <div
        className="container"

      >
        <div className="row">
          <div className="col-12 col-md-12 mb-3 mt-3">
            <div className="card" style={{ height: "100%" }}>
              <div className="card-body">
                <div className="d-flex justify-content-between">
                  <h6
                    className="mSectionTitle mNoMargin"
                  >
                    {STR_BUSINESS[appContext.language]}
                  </h6>

                </div>

                <h1
                  style={{
                    fontSize: "30px",
                    marginTop: "40px",
                  }}
                  className="text-secondary font-bold"
                >
                  {appContext.currentBusinessData?.name}
                </h1>
                <h1
                  style={{
                    fontSize: "20px",
                  }}
                  className="text-muted font-bold"
                >
                  ID: {appContext.currentBusinessData?.uid}
                </h1>


              </div>
            </div>
          </div>


          {/**<div className="col-12 col-md-12 mb-3 mt-3">
            <div className="row mt-4">

              <div className="col-4 col-md-3 mb-2 mt-2">
                <button
                  className="btn btn-sm btn-block"
                  onClick={createGoal}
                  style={{
                    height: "100%",
                  }}
                >
                  <TbTarget size={30} /><br />
                  {STR_CREATE_GOAL[appContext.language]}
                </button>
              </div>

              <div className="col-4 col-md-3 mb-2 mt-2">
                <button
                  className="btn btn-sm btn-block"
                  onClick={createGoal}
                  style={{
                    height: "100%",
                  }}
                >
                  <MdDataUsage size={30} /><br />
                  {STR_SUBSCRIPTION_PACKAGE[appContext.language]}
                </button>
              </div>

              <div className="col-4 col-md-3 mb-2 mt-2">
                <button
                  className="btn btn-sm btn-block"
                  onClick={createGoal}
                  style={{
                    height: "100%",
                  }}
                >
                  <MdEdit size={30} /><br />
                  {STR_EDIT[appContext.language]}
                </button>
              </div>

            </div>
          </div>*/}

        </div>
      </div>
    )
  } else {
    return (
      <div
        className="container mSupportLoading"
      >
        {getInlineLoader()}
      </div>
    );
  }


}